<script setup lang="ts">
// TODO:
// - Add a size prop and make it match the size of the BaseAvatar component.
// - We currently have a "circle" avatar, but we also want to display the logo full width, without being cut off.
//   because in a circle, there's always a risk of cutting off the logo.
// - We should decide between a separate component for the full width logo, or adding something like a "shape" or "variant" prop.
// - Also, it would be really awesome if we could detect the main color and then use that as the border color.
//   (but that's something for the backend)

interface OrganizionLogoProps {
  name: string
  logoUrl?: string
  profileColor?: string
  status?: OrganizationStatus
}

const { organization, showTooltip, size }
  = defineProps<{
    organization: OrganizionLogoProps
    // Wether to show the logo as a square/circle icon or full width.
    // squareLogo?: boolean
    // Wether to show the tooltip.
    showTooltip?: boolean
    // The size of the avatar.
    size?: 'sm' | 'lg'
  }>()

const isArchived = computed(() => organization.status == OrganizationStatus.Archived)

// This is uggly.
// If we want't to show the logo full width, we're not showing it as an avatar.
// And when it doesn't have a logo url, we're not showing it as an avatar either.
// const asSquareLogo = computed(() => !organization.logoUrl || squareLogo)
const asSquareLogo = true

// take the first 2 chars of the organization name for now
const initials = computed(() => organization.name?.substring(0, 2) || '')

const colorClass = computed(() => {
  return organization.profileColor || getRandomColor()
})

// TODO: this doesn't work yet, need to figure out something else.
// tooltip should also be modified.
const statusClass = computed(() => {
  if (isArchived.value) {
    return 'border-danger-200 dark:border-danger-700'
  }
  return ''
})

const containerSizeClass = computed(() => {
  if (size === 'lg') {
    return 'size-16'
  }
  return 'size-8'
})

</script>

<template>
  <BaseAvatar
    v-if="!organization.logoUrl"
    :text="initials"
    :class="[colorClass, statusClass]"
    :data-nui-tooltip="organization.name"
    :size="size"
  />
  <div
    v-else-if="organization.logoUrl"
    :class="[
      'border-primary-500 shrink-0 rounded-full border-2 bg-cover bg-center',
      containerSizeClass
    ]"
    :style="{ backgroundImage: `url(${organization.logoUrl})` }"
    :data-nui-tooltip="organization.name"
  />
</template>
